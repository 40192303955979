import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { EventEmitter, Input, Output } from "@angular/core";
import { LoaderService } from "src/app/shared/services/loader.service";
import { CareerService } from "src/app/shared/services/career.service";
@Component({
  selector: "app-pre-registration-form",
  templateUrl: "./pre-registration-form.component.html",
  styleUrls: ["./pre-registration-form.component.scss"],
})
export class PreRegistrationFormComponent implements OnInit {
  @Input() leadId: any;
  @Input() partner: any;
  @Output() done: EventEmitter<any> = new EventEmitter();

  name: string;
  city: string;
  phone: string;
  email: string;

  constructor(private formBuilder: FormBuilder, private loaderService: LoaderService, private careerService: CareerService) {}

  ngOnInit(): void {
    this.name = this.partner && this.partner.name ? this.partner.name : null;
    this.city = this.partner && this.partner.city ? this.partner.city : null;
    this.phone = this.partner && this.partner.maskedPhone ? this.partner.maskedPhone : null;
    this.email = this.partner && this.partner.maskedEmail ? this.partner.maskedEmail : null;
  }

  sendMessages(isResend) {
    this.loaderService.startLoader();

    this.careerService.sendFinishRegistrationNotifications({ leadId: this.leadId }).subscribe(
      (value) => {
        if (value.error) {
          this.done.emit({
            title: value.title,
            subtitle: "Não foi possível enviar o as mensagens para você. Favor tentar novamente.",
            showImage: false,
          });
        } else if (value.registrationFinished) {
          this.done.emit({
            title: "Seu cadastro já foi finalizado!",
            subtitle: "",
            showImage: true,
          });
        } else {
          const sendEmailContainer = document.getElementById("sendEmailContainer");
          const resendEmailContainer = document.getElementById("resendEmailContainer");

          if (sendEmailContainer) sendEmailContainer.style.display = "none";
          if (resendEmailContainer) resendEmailContainer.style.display = "block";

          if (isResend) {
            const successSendingTitle = document.getElementById("successSendingTitle");
            if (successSendingTitle) successSendingTitle.innerHTML = "Mensagens reenviadas com sucesso!";
          }
        }

        this.loaderService.stopLoader();
      },
      (err) => {
        this.done.emit({
          title: "Erro ao enviar as mensagens.",
          subtitle: "Não foi possível enviar as mensagens para você. Favor tentar novamente.",
          showImage: false,
        });
        this.loaderService.stopLoader();
      }
    );
  }
}
