<div class="partner-form">
    <p>Olá {{name}}! Você passou pela primeira etapa do seu cadastro!</p>
    <div class="info-content w-100">
        <div class="info my-1" *ngIf="phone"> • <span class="bold"> Telefone: </span> {{phone}} </div>

        <div class="info my-1" *ngIf="email"> • <span class="bold"> E-mail: </span> {{email}} </div>

        <div class="info my-1" *ngIf="city"> • <span class="bold"> Cidade: </span> {{city}} </div>
    </div>

    <p>Agora é só acessar o <strong>Portal do Parceiro</strong> para concluir seu cadastro. 
        Clique no botão abaixo para receber uma mensagem no seu <strong>WhatsApp</strong> e 
        um <strong>e-mail</strong> explicando o passo a passo.</p>

    <div id="sendEmailContainer" style="display: flex; justify-content: center">
        <sy-button label="Receber mensagens" (click)="sendMessages(false)"></sy-button>
    </div>

    <div id="resendEmailContainer" style="display: none;">
        <p id="successSendingTitle">Mensagens enviadas com sucesso!</p>

        <p class="mb-4">Caso não tenha recebido, clique no botão abaixo:</p>

        <div class="d-flex justify-content-center mb-4">
            <sy-button label="Reenviar mensagens" (click)="sendMessages(true)"></sy-button>
        </div>

        <p class="mb-0">Lembre-se de conferir a caixa de spam.</p>
    </div>
</div>